@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    display: block;
  }
}
.odin {
  width: 100vw;
  display: flex;
  justify-content: center;
}
.allfather_container {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
}
.appear_anim {
  animation: appear 2s forwards linear;
}
.element {
  width: 33vw;
  height: 18vw;
  margin: 0.5vh 0px;
}

.invisible {
  opacity: 0;
  display: hidden;
}
.element_inside_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.work_title {
  opacity: 0;
  color: blueviolet;
}
.element_inside_container:hover .work_title {
  opacity: 1;
  transition: 1s all;
}
.container_inner {
  width: 100%;
  height: 100%;
  z-index: 20;
}
.container_inner * {
  text-decoration: none;
}
.bg_spot {
  position: absolute;
  width: 33vw;
  height: 18vw;
}
.bg_spot img {
  width: 33vw;
  height: 18vw;
}
.anchor {
  display: none;
}
.film_outer {
  padding: 5vh 0vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}
.film_inner {
  width: 80vw;
  background-color: brown;
  min-height: 60vh;
}
.film_title {
  color: black;
  z-index: 20;
}
#ovunque {
  width: 500px;
  min-height: 500px;
  z-index: 10;
  border: 10px solid black;
}
.pt_spots {
  padding: 5px;
  text-shadow: 0px 5px 3px rgba(0, 0, 0, 1);
}
.title {
  color: white;
  width: 100%;
  text-align: center;
  margin: 0vh 0vh 2vh 0vh;
  /* margin:25px 0px 0px 25px; */
}
.direction_outer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content_outer {
  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.content_outer h1 {
  color: white;
  font-size: 300%;
  text-align: center;
  font-weight: lighter;
}
.content_outer p {
  max-width: 70vw;
  color: White;
  text-align: justify;
  padding: 2vh 0vh;
}
.hamburger_menu {
  display: none;
}

@media screen and (max-width: 1100px) {
  .element,
  .bg_spot,
  .bg_spot img {
    width: 49vw;
    height: 27vw;
  }
  .element {
    margin: 0.25vh 0vh;
  }
  .direction_outer {
    height: unset;
    min-height: 75vh;
  }
}
@media screen and (max-width: 500px) {
  .element,
  .bg_spot,
  .bg_spot img {
    width: 100vw;
    height: 56vw;
  }
  .element {
    margin: 1vh 0vh;
  }
  .direction_outer {
    height: unset;
  }
  .film_container_c {
    margin: 1vh 0vh 40vh 0vh;
  }
  .content_outer {
    width: 90vw;
    padding: 5vh 0vh;
  }
  .content_outer p {
    max-width: unset;
    text-align: justify;
    word-break: break-all;
  }
}
