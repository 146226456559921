.navbar_container {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 6vh;
  z-index: 999;
}
.nav {
  width: 80vw;
  display: flex;
  justify-content: center;
}
.nav ul {
  display: flex;
  flex-direction: row;
  list-style: none;
}
.nav ul li {
  margin: 0rem 2rem;
  cursor: pointer;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 120%;
  letter-spacing: 2px;
}
.nav * {
  text-decoration: none;
  color: white;
}
.nav_footer {
  display: none;
}
.close {
  display: none;
}

@media screen and (max-width: 1100px) {
  .nav {
    width: unset;
  }
  .nav ul li {
    font-size: 120%;
    margin: 0rem 1rem;
  }
}
@media screen and (max-width: 500px) {
  .nav {
    display: none;
  }
  .navbar_container {
    top: 0px;
  }

  .mobile_nav {
    position: fixed;
    z-index: 100;
    background-color: black;
    top: 0px;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    justify-content: center;
    align-items: center;
  }
  .mobile_nav ul {
    list-style: none;
    margin-top: 17vh;
    margin-bottom: 75px;
  }
  .mobile_nav ul li {
    text-align: center;
    margin: 10vh 0vh;
    font-weight: bolder;
    font-family: "Montserrat";
    letter-spacing: 3px;
  }
  .close_position {
    top: 50px;
    right: 40px;
  }
  .hamburger_menu {
    display: flex;
    position: fixed;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    z-index: 200;
  }
  .line {
    width: 35px;
    height: 4px;
    margin: 0.7vh 0vh;
    background-color: white;
    box-shadow: 0.5px 1px 5px 3px black;
    transition: all 0.8s;
  }
  .change {
    top: 30px;
    right: 20px;
  }

  .change .line-1 {
    transform: rotateZ(-405deg) translate(-19px, 5px);
    z-index: 200;
  }

  .change .line-3 {
    transform: rotateZ(405deg) translate(-8px, 7px);
    z-index: 200;
  }

  .change .line-2 {
    opacity: 0;
  }
  .nav_footer {
    position: absolute;
    bottom: 0px;
    min-height: 10vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: white;
  }
  .nav_footer_test {
    display: flex;
    justify-content: center;
  }
  .nav_footer_test div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: black;
  }
  .nav_footer_row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  .nav_footer_row * {
    color: black;
  }
  .nav_footer_row img {
    width: 90vw;
    max-width: 300px;
    max-height: 150px;
  }
}
