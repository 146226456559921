.commercial_paragraph p {
  text-align: justify;
  padding-top: 5vh;
}
.commercials_partners {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 8vh;
}
.commercials_partners img {
  width: 100px;
  height: 100px;
}
.commercials_row {
  width: 100vw;
  height: 75vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 15vh 0vh;
}
.commercials_half {
  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.commercials_inner {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.commercials_title {
}

@media screen and (max-width: 1100px) {
  .commercial_paragraph p {
    padding: 1vh;
  }
  .commercials_partners {
    padding-top: 1vh;
  }
  .commercials_inner {
    justify-content: center;
  }
  .commercials_inner_ {
    width: 400px;
    height: 400px;
  }
  .commercials_row {
    margin: 7vh 0vh;
    height: unset;
  }
}
@media screen and (max-width: 500px) {
  .commercials_row {
    flex-direction: column-reverse;
  }
  .commercials_inner {
    width: 300px;
    height: unset;
  }
  .commercial_paragraph p {
    padding: unset;
    text-align: center;
  }
  .commercials_inner_ {
    width: 300px;
    height: 300px;
    margin-top: 10vh;
  }
  .commercials_partners {
    padding: 3vh 0vh 0vh 0vh;
  }
  .commercials_partners img {
    margin: 10px;
    width: 75px;
    height: 75px;
  }
}
