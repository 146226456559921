* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-weight: lighter;
  color: white;
}
body {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: black;
  /* background-color: rgb(90, 90, 90); */
}
p {
  font-family: "Montserrat";
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat";
}
.test {
  width: 100vw;
  height: 100vh;
  background-color: brown;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter .fade-enter-active {
  opacity: 1;
  transition: opacity 500m ease-in;
}
.montserrat {
  font-family: "Montserrat";
}
.philosopher {
  font-family: "Philosopher";
}
