.bio_row {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 15vh 0vh;
}
.bio_half {
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bio_inner {
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  background: #0e0e0e;
  box-shadow: 37px 37px 75px #0d0d0d, -37px -37px 75px #0f0f0f;
}
.capucci_bio {
  background-image: url("../../../images/capucci-bio.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bio_paragraph p {
  text-align: justify;
  max-width: 35vw;
}
.bio_reference {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.bio_reference img {
  width: 50px;
  height: 50px;
  margin: 0px 100px;
}
.bio_inner_ {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 500px;
}

@media screen and (max-width: 1100px) {
  .bio_inner {
    width: 100vw;
    min-height: 55vw;
  }
  .bio_paragraph {
    max-width: 90vw;
  }
  .bio_paragraph p {
    max-width: 90vw;
  }
  .bio_row {
    margin: 5vh 0vh;
    flex-direction: column;
  }
  .bio_inner_ {
    min-height: 400px;
  }
  .bio_title {
    display: none;
  }
  .bio_half {
    width: unset;
  }
}
@media screen and (max-width: 500px) {
  .bio_row {
    flex-direction: column;
    margin: 10vh 0vh;
  }
  .bio_paragraph,
  .bio_paragraph p {
    max-width: unset;
    width: 300 px;
  }
  .bio_inner {
    width: 300px;
    height: 300px;
  }
  .bio_inner_ {
    min-height: unset;
    margin: 5vh 0vh;
    transition: 0.5s all ease-in-out;
  }
  .bio_reference {
    margin-top: 2vh;
  }
  .bio_half {
    width: 90vw;
  }
  .bio_reference img {
    margin: 20px 50px;
    width: 75px;
    height: 75px;
  }
}
