.footer_outer {
  width: 100vw;
  background-color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.footer_container {
  width: 90vw;
  display: flex;
  justify-content: left;
  align-items: center;
}
.footer_content {
  min-width: 20vw;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: black;
  align-items: left;
}
.footer_test {
  display: flex;
  justify-content: center;
  align-content: center;
}
.footer_test img {
  width: 50vw;
}
.footer_logos {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.footer_logos div {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: black;
}
.footer_logos div {
  margin: 0vh 1vw;
}
.contact_outer div {
  padding: 1vh 0vh;
  margin: 0vh 1vw;
}
.contact_outer a {
  color: black;
  font-weight: bold;
}
.credits_row {
  width: 100vw;
  height: 30px;
  background-color: black;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat";
}
.fcc {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5vh 0vh;
}

.footer_outer,
.footer_content {
  min-height: unset;
}
.footer_container {
  justify-content: center;
}
.footer_test * {
  text-align: center;
}
.contact_outer div {
  display: flex;
  justify-content: center;
  padding: 2vh 0vh 0vh 0vh;
}

@media screen and (max-width: 500px) {
  .footer_test img {
    width: 90vw;
    max-height: 200px;
  }
  .contact_outer a img {
    width: 60px;
    height: 60px;
  }
}
