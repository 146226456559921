.subspot_allfat {
  min-height: 85vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.iframe_video {
  width: 40vw;
  min-height: 40vh;
}
.spot_info_cont {
  width: 40vw;
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spot_info h1 {
  padding: 2vh 0vh;
}

@media screen and (max-width: 500px) {
  .subspot_allfat {
    flex-direction: column;
  }
  .iframe_video {
    width: 100vw;
    margin: 5vh 0vh;
  }
  .spot_info_cont {
    width: 90vw;
  }
  .credits_spot_paragraph {
    padding: 3vh 0vh 5vh 0vh;
  }
}