.shorts_row {
  width: 100vw;
  min-height: 50vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 20vh 0vh;
}
.shorts_row_reverse {
  width: 100vw;
  min-height: 50vh;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-evenly;
  margin: 20vh 0vh;
}
.short_container {
  width: 60vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shorts_content_container {
  width: 60vw;
  height: 50vh;
  -webkit-box-shadow: inset 5px 15px 50px 50px #000000;
  box-shadow: inset 7px 20px 110px 110px #000000;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
}
.shorts_video_container {
  position: absolute;
  margin-top: 3px;
}
.shorts_video_container video {
  width: 60vw;
  min-height: 50vh;
  object-fit: fill;
}
.shorts_content_container:hover {
  -webkit-box-shadow: inset 5px 5px 15px 15px #000000;
  box-shadow: inset 5px 15px 50px 50px #000000;
  transition: 0.5s all ease-out;
}
.shorts_content_container:hover .titles_shorts {
  opacity: 1;
  transition: all 0.5s ease-out;
}

.foot_film {
}
.shorts_description_container {
  min-width: 40vw;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.shorts_desc_ {
  max-width: 20vw;
}
.shorts_title {
  text-align: center;
}
.shorts_description {
  padding: 3vh 0vh;
  text-align: center;
}
.vimeo {
  width: 50px;
  height: 50px;
}
.vimeo_logo_short {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1100px) {
  .shorts_row,
  .short_container,
  .shorts_content_container {
    min-height: unset;
  }

  .shorts_content_container {
    height: unset;
  }
  .shorts_desc_ {
    max-width: 80vw;
  }
  .shorts_row_reverse {
    min-height: 30vh;
  }
  .shorts_row_reverse,
  .shorts_row {
    flex-direction: column;
    margin: 10vh 0vh;
  }
  .shorts_content_container {
    width: 100vw;
  }
  .shorts_video_container video {
    width: 100vw;
    min-height: unset;
  }
  .shorts_video_container {
    position: unset;
  }
  .shorts_description_container {
    min-width: unset;
    min-height: unset;
    margin: 3vh 0vh;
  }
}
@media screen and (max-width: 500px) {
  .shorts_row_reverse {
    flex-direction: column;
    margin-top: 20vh;
  }
  .shorts_row {
    flex-direction: column;
  }
  .shorts_description_container {
    width: unset;
    margin-top: 10vh;
  }
  .shorts_desc_ {
    max-width: unset;
    width: 90vw;
  }
  .shorts_container {
    min-height: 25vh;
    margin-top: 10vh;
    width: unset;
  }
  .shorts_content_container,
  .shorts_video_container {
    width: 100vw;
  }
  .shorts_content_container video {
    width: 100vw;
    min-height: unset;
  }
  .vimeo {
    width: 75px;
    height: 75px;
  }
}
