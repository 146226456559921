.film_container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20vh 0vh;
  flex-direction: column;
}
.video_container {
  position: relative;
  display: block;
  height: inherit;
}
.video_container video {
  width: 100vw;
  object-fit: fill;
}
.content_container {
  width: 100vw;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
  top: 0px;
  bottom: 0px;
}

.content_container:hover {
  background-color: rgba(1, 1, 1, 0.3);
  transition: 0.5s all ease-out;
}
.goto_film {
  overflow: hidden;
  position: absolute;
  top: 5px;
}
.titles_film {
  opacity: 0;
  margin-top: 3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}
.video_container:hover .titles_film {
  opacity: 1;
  transition: all 0.5s ease-out;
}
.arrow {
  width: 70px;
}
.img_container {
  display: none;
}

@media screen and (max-width: 1100px) {
  /* .film_container {
    width: 100vw;
    max-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 5vh 0vh;
  }
  .video_container {
    position: absolute;
    margin-top: 3px;
  }
  .video_container video {
    width: 100vw;
    height: 56.5vw;
    object-fit: fill;
  }
  .content_container {
    width: 100vw;
    max-height: 56.5vw;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .titles_film {
    opacity: 0.5;
  } */
  .titles_film {
    margin-top: 1vh;
  }
}
@media screen and (max-width: 500px) {
  .video_container {
    display: none;
  }

  .img_container {
    display: flex;
    position: relative;
    width: 100vw;
    height: 56vw;
  }
  .img_container img {
    width: 100vw;
    height: 56vw;
  }
  .film_container {
    margin: 8vh 0vh;
    height: 50vh;
  }
  .titles_film {
    opacity: 1;
    font-size: 90%;
  }
  .arrow {
    display: none;
  }
  .goto_film {
    z-index: 20;
  }
  .titles_film h1 {
    font-size: 160%;
    width: 100vw;
    text-align: center;
    margin: 0vh 0vh 2vh 0vh;
  }
}
