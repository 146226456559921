body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "Philosopher";
  src: url("./fonts/philosopher/Philosopher-Regular.ttf") format("truetype")
      url("./fonts/philosopher/Philosopher-Regular.woff2") format("woff2"),
    url("./fonts/philosopher/Philosopher-Regular.woff") format("woff"),
    url("./fonts/philosopher/Philosopher-Regular.svg#Philosopher-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/montserrat/montserrat/Montserrat-Regular.woff2")
      format("woff2"),
    url("./fonts/montserrat/montserrat/Montserrat-Regular.woff") format("woff"),
    url("./fonts/montserrat/montserrat/Montserrat-Regular.ttf")
      format("truetype"),
    url("./fonts/montserrat/montserrat/Montserrat-Regular.svg#Montserrat-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
