.acting_container {
    flex-direction: column;
    height: unset !important;
}

.acting_direction_paragraph {
    margin-bottom: 24px;
    word-break: unset !important;
}

.acting_link {
    margin-bottom: 24px;
}

@media (min-width: 768px) {
    .acting_link {
        margin-bottom: 56px;
    }
}

@media (min-width: 1101px) {
    .acting_link {
        margin-bottom: 72px;
    }
}