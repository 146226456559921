.subpag_film_cont {
  padding-top: 15vh;
  width: 100vw;
  text-align: center;
}
.subpage_film_cover {
  width: 80vw;
  height: 60vh;
}
.cover_cont {
  width: 100vw;
}
.cover_cont img {
  width: 100vw;
}
.details_outer {
  display: flex;
  flex-direction: row;
}
.first_half,
.second_half {
  width: 50vw;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trailer_ img {
  max-width: 60px;
}
.film_title h1 {
  font-size: 500%;
  max-width: 30vw;
}
.half_container {
  width: 40vw;
}
.description_container {
  text-align: justify;
}
.film_title,
.credits_,
.trailer_ {
  padding: 2vh 0vh;
}
.musical_video_container {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.embed_container,
.video_musical_info {
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.embed_yt {
  width: 40vw;
  min-height: 20vw;
}
.video_info_container {
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 15vw;
}
.trailer_ {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.trailer_ img {
  padding: 5px;
}

@media screen and (max-width: 1100px) {
  .details_outer {
    min-height: 90vh;
  }
  .film_title h1 {
    font-size: 400%;
    max-width: unset;
  }
  .half_container {
    width: unset;
    max-width: 100vw;
  }
  .first_half,
  .second_half {
    min-height: unset;
    width: 90vw;
  }
  .details_outer {
    flex-direction: column;
    align-items: center;
    min-height: 60vh;
  }
  .trailer_ {
    justify-content: center;
  }
  .description_container {
    padding: 5vh 0vh 10vh 0vh;
  }
  .musical_video_container {
    flex-direction: column;
  }
  .embed_container {
    width: 100vw;
  }
  .embed_yt {
    width: 80vw;
    min-height: 40vw;
  }
  .video_musical_info,
  .video_info_container {
    width: 80vw;
  }
  .video_musical_info,
  .embed_container {
    margin: 3vh 0vh;
  }
}
