@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

.header_bg {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.reel_button_outer {
  z-index: 200;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100vw;
  top: 93vh;
}
.reel_button_outer a {
  text-decoration: none;
}
.reel_button_mobile {
  display: none;
}
.reel_button_mobile {
  display: block;
  --border-width: 5px;
  text-decoration: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 70px;
  font-size: 140%;
  letter-spacing: 2px;
  color: white;
  background: black;
  border-radius: var(--border-width);
  cursor: pointer;
}
.reel_button_mobile:after {
  position: absolute;
  text-decoration: none;
  content: "";
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  z-index: -1;
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient(
    60deg,
    hsl(0, 0%, 21%),
    hsl(0, 0%, 94%),
    hsl(0, 0%, 69%),
    hsl(0, 0%, 15%),
    hsl(0, 0%, 68%),
    hsl(0, 0%, 85%),
    hsl(0, 0%, 5%),
    hsl(0, 0%, 47%)
  );
  background-size: 300% 300%;
  background-position: 0 50%;
  border-radius: calc(2 * var(--border-width));
  animation: moveGradient 3s alternate infinite;
}

.bg_reel {
  position: absolute;
}
.bg_reel video {
  width: 100vw;
}
.navbar_container {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 8.5vh;
  z-index: 999;
}
.logo_container,
.nav {
  width: 80vw;
  display: flex;
  justify-content: center;
}

.nav ul {
  display: flex;
  flex-direction: row;
  list-style: none;
}
.nav ul li {
  margin: 0rem 2rem;
}
.nav * {
  text-decoration: none;
  color: white;
}
.logo_row {
  width: 100vw;
  min-height: 10vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 100;
}
.vimeo_cont,
.insta_cont {
  width: 50px;
}

.logo_cont {
  width: 50vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logo_cont img {
  width: 50vw;
}

@media screen and (max-width: 1100px) {
  .header_bg {
    min-height: 60vh;
  }
  .reel_button_outer {
    top: 52.5vh;
  }
}
@media screen and (max-width: 500px) {
  .logo_row {
    margin-bottom: 100px;
  }
  .insta_cont,
  .vimeo_cont {
    display: none;
  }
  .logo_cont img {
    width: 100vw;
  }

  .bg_reel {
    display: none;
  }
  .header_bg {
    min-height: 35vh;
  }
  .reel_button_outer {
    top: 25vh;
  }
  .reel_button {
    display: none;
  }
}
