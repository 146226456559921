.actingSection_container {
    max-width: 90vw;
    
}

.actingSection_container h2 {
    margin-bottom: 16px;
}

.actingSection_container p {
    margin-bottom: 24px;
    word-break: unset !important;
}

.actingSection_container {
    margin-bottom: 40px;
}


.acting_section_img { 
    width: 100%;
    height: 100%;
    margin-bottom: 8px;
}


@media (min-width: 768px) {
    .actingSection_container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    
    .acting_section_img {
        display: block;
    }
}

@media (min-width: 1101px) {
    .actingSection_container {
        flex-wrap: unset;
        align-items: unset;
        justify-content: space-between;
    }

    .actingSection_container div:first-child,
    .actingSection_container div:last-child {
        max-width: 50%;
    }

    .actingSection_container div:first-child {
        padding-right: 56px;
    }

    .actingSection_container div:first-child h2 {
        font-size: 36px;
        margin-bottom: 32px;
    }
}