.nav_sub_container {
  width: 100vw;
  min-height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.arrow_sub {
  width: 200px;
  transform: rotate(180deg);
}
.cap_sub {
  width: 450px;
}

.nav_logo {
  min-width: 30vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.athird {
  min-width: 30vw;
}
.xx {
  min-height: 50px;
}

@media screen and (max-width: 1100px) {
  .arrow_sub {
    width: 150px;
  }
}
@media screen and (max-width: 500px) {
  .cap_sub {
    width: 300px;
  }
  .xx {
    display: none;
  }
  .nav_logo {
  }
  .nav_sub_container {
    justify-content: center;
  }
  .arrow_div {
    display: none;
  }
}
