.spots_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3vh 0vh;
}
.spots_button {
  padding: 3vh 0vh;
  font-weight: bolder;
}
.spots_button button {
  min-width: 120px;
  min-height: 30px;
  border-radius: 20px;
  border: none;
  font-weight: bolder;
}
.spots_button span {
  color: black;
}
.spots_outer {
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
